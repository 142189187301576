import { template as template_39a4421042d647ac895b50b429c1ad5a } from "@ember/template-compiler";
const WelcomeHeader = template_39a4421042d647ac895b50b429c1ad5a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
