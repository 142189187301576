import { template as template_9d0b6649360c4caba91a6fb7f0c9a180 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import i18n from "discourse-common/helpers/i18n";
export default class ActivationEmailForm extends Component {
    @action
    newEmailChanged(event) {
        this.args.updateNewEmail(event.target.value);
    }
    static{
        template_9d0b6649360c4caba91a6fb7f0c9a180(`
    <p>{{i18n "login.provide_new_email"}}</p>
    <input
      {{on "input" this.newEmailChanged}}
      value={{@email}}
      type="text"
      class="activate-new-email"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
