import { template as template_f2f01b45184d4348b96afa3817c959fa } from "@ember/template-compiler";
const FKLabel = template_f2f01b45184d4348b96afa3817c959fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
